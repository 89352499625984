import React from 'react';
import { isDayOrMoment, useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedDateSeparator = function (props) {
    var date = props.date, formatDate = props.formatDate, _a = props.position, position = _a === void 0 ? 'right' : _a, unread = props.unread;
    var _b = useTranslationContext('DateSeparator'), t = _b.t, tDateTimeParser = _b.tDateTimeParser;
    if (typeof date === 'string')
        return null;
    var parsedDate = tDateTimeParser(date.toISOString());
    var formattedDate = formatDate
        ? formatDate(date)
        : isDayOrMoment(parsedDate)
            ? parsedDate.calendar()
            : parsedDate;
    return (React.createElement("div", { className: 'str-chat__date-separator' },
        (position === 'right' || position === 'center') && (React.createElement("hr", { className: 'str-chat__date-separator-line' })),
        React.createElement("div", { className: 'str-chat__date-separator-date' }, unread ? t('New') + " - " + formattedDate : formattedDate),
        (position === 'left' || position === 'center') && (React.createElement("hr", { className: 'str-chat__date-separator-line' }))));
};
/**
 * A simple date separator between messages.
 */
export var DateSeparator = React.memo(UnMemoizedDateSeparator);
