var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Suspense, useMemo } from 'react';
import { getStrippedEmojiData } from '../Channel/emojiData';
import { useEmojiContext } from '../../context/EmojiContext';
import { useMessageContext } from '../../context/MessageContext';
var UnMemoizedReactionsList = function (props) {
    var additionalEmojiProps = props.additionalEmojiProps, onClick = props.onClick, propReactionCounts = props.reaction_counts, propReactionOptions = props.reactionOptions, propReactions = props.reactions, _a = props.reverse, reverse = _a === void 0 ? false : _a;
    var _b = useEmojiContext('ReactionsList'), Emoji = _b.Emoji, emojiConfig = _b.emojiConfig;
    var _c = useMessageContext('ReactionsList'), message = _c.message, onReactionListClick = _c.onReactionListClick;
    var _d = emojiConfig || {}, defaultMinimalEmojis = _d.defaultMinimalEmojis, fullEmojiData = _d.emojiData, emojiSetDef = _d.emojiSetDef;
    var reactions = propReactions || message.latest_reactions || [];
    var reactionCounts = propReactionCounts || message.reaction_counts || {};
    var reactionOptions = propReactionOptions || defaultMinimalEmojis;
    var reactionsAreCustom = !!(propReactionOptions === null || propReactionOptions === void 0 ? void 0 : propReactionOptions.length);
    var emojiData = useMemo(function () { return (reactionsAreCustom ? fullEmojiData : getStrippedEmojiData(fullEmojiData)); }, [fullEmojiData, reactionsAreCustom]);
    if (!reactions.length)
        return null;
    var getTotalReactionCount = function () {
        return Object.values(reactionCounts).reduce(function (total, count) { return total + count; }, 0);
    };
    var getCurrentMessageReactionTypes = function () {
        var reactionTypes = [];
        reactions.forEach(function (_a) {
            var type = _a.type;
            if (reactionTypes.indexOf(type) === -1) {
                reactionTypes.push(type);
            }
        });
        return reactionTypes;
    };
    var getEmojiByReactionType = function (type) {
        var reactionEmoji = reactionOptions.find(function (option) { return option.id === type; });
        return reactionEmoji;
    };
    var getSupportedReactionMap = function () {
        var reactionMap = {};
        reactionOptions.forEach(function (_a) {
            var id = _a.id;
            return (reactionMap[id] = true);
        });
        return reactionMap;
    };
    var messageReactionTypes = getCurrentMessageReactionTypes();
    var supportedReactionMap = getSupportedReactionMap();
    var supportedReactionsArePresent = messageReactionTypes.some(function (type) { return supportedReactionMap[type]; });
    if (!supportedReactionsArePresent)
        return null;
    return (React.createElement("div", { "aria-label": 'Reaction list', className: "str-chat__reaction-list " + (reverse ? 'str-chat__reaction-list--reverse' : ''), "data-testid": 'reaction-list', onClick: onClick || onReactionListClick, onKeyPress: onClick || onReactionListClick, role: 'figure' },
        React.createElement("ul", null,
            messageReactionTypes.map(function (reactionType) {
                var emojiObject = getEmojiByReactionType(reactionType);
                return emojiObject ? (React.createElement("li", { key: emojiObject.id },
                    React.createElement("button", { "aria-label": "Reactions: " + reactionType },
                        React.createElement(Suspense, { fallback: null },
                            React.createElement(Emoji, __assign({ data: emojiData, emoji: emojiObject, size: 16 }, (reactionsAreCustom ? additionalEmojiProps : emojiSetDef)))),
                        "\u00A0"))) : null;
            }),
            React.createElement("li", null,
                React.createElement("span", { className: 'str-chat__reaction-list--counter' }, getTotalReactionCount())))));
};
/**
 * Component that displays a list of reactions on a message.
 */
export var ReactionsList = React.memo(UnMemoizedReactionsList);
